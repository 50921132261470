import React, { useEffect } from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import CapabilityHome from '../components/home/capability-home'
import Need from '../components/home/need'
import Actions from '../components/home/actions'
import ParentArea from '../components/parent/parent-area'
import TopScroll from '../components/parent/top-scroll'

const IndexPageWrapper = styled.div`
  background: rgba(184, 225, 210, 1);
  background: linear-gradient(
    -50deg,
    rgba(184, 225, 210, 1) 0%,
    rgba(255, 255, 255, 1) 90%
  );
  @media (max-width: 767px) {
    overflow: hidden;
  }

  @media (max-width: 767px) {
    overflow: hidden;
  }
  @media (max-width: 575px) {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      70deg,
      rgba(255, 255, 255, 1) 40%,
      rgba(184, 225, 210, 1) 60%
    );
  }
`

const IndexPageInnerWrapper = styled.div`
  background: rgb(244, 242, 247);
  background: linear-gradient(
    50deg,
    rgba(244, 242, 247, 1) 0%,
    rgba(255, 255, 255, 0.35) 60%
  );
  @media (max-width: 575px) {
    background: linear-gradient(
      50deg,
      rgba(244, 242, 247, 1) 0%,
      rgba(255, 255, 255, 0.35) 20%
    );
  }
`

const IndexPage = ({ location }) => {
  useEffect(() => {
    window.location.href = "https://lp.bnpparibas.pl/misjaedukacja/";
  }, [])

  return (
    <>
      <IndexPageWrapper>
        <IndexPageInnerWrapper>
          <SEO title="" />
          <Layout location={location}>
            {false && <CapabilityHome />}
            <Need />
            <Actions />
            <ParentArea page="home" />
            <TopScroll />
          </Layout>
        </IndexPageInnerWrapper>
      </IndexPageWrapper>
    </>
  )
}

export default IndexPage
